const BLACK = '#111111';
const WHITE = '#FFFFFF';
const YIQ_THRESHOLD = 186;
export const bestContrastColor = themeColor => {
  if (themeColor === null) {
    return WHITE;
  }
  if (themeColor && themeColor[0] === '#') {
    themeColor = themeColor.slice(1);
  }
  const isFullHexValue = themeColor.length === 6;
  const rStr = isFullHexValue ? themeColor.substring(0, 2) : `${themeColor[0]}${themeColor[0]}`;
  const gStr = isFullHexValue ? themeColor.substring(2, 4) : `${themeColor[1]}${themeColor[1]}`;
  const bStr = isFullHexValue ? themeColor.substring(4, 6) : `${themeColor[2]}${themeColor[2]}`;
  const r = parseInt(rStr, 16);
  const g = parseInt(gStr, 16);
  const b = parseInt(bStr, 16);

  // Using YIQ function: https://24ways.org/2010/calculating-color-contrast/
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= YIQ_THRESHOLD ? BLACK : WHITE;
};