'use es6';

import { RtlLangs } from '../../constants/RtlLangs';
export const getPageProps = ({
  survey: {
    form,
    brandFooter
  }
}) => ({
  form,
  brandFooter
});
export const getSurveyLanguage = ({
  form: {
    metaData = []
  }
}) => {
  const lang = metaData.find(({
    name
  }) => name === 'lang') || {};
  return lang.value || '';
};
export const getIsLanguageRtl = ({
  survey
}) => {
  const lang = getSurveyLanguage(survey);
  return RtlLangs.includes(lang.split('-')[0]);
};
export const isSurveyPublished = ({
  survey: {
    form: {
      isPublished
    }
  }
}) => isPublished;
export const getFormProps = ({
  survey: {
    form
  } = {}
}) => form;