export const ERRORS = {
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  SUBMISSION_PERIOD_ENDED: 'SUBMISSION_PERIOD_ENDED'
};
export const ERROR_TYPES = {
  GENERAL: 'GENERAL',
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  TOO_LATE: 'TOO_LATE',
  SUBMISSION_PERIOD_ENDED: 'SUBMISSION_PERIOD_ENDED'
};
export const ERROR_IMAGES = {
  GENERAL: 'general',
  RATE_LIMIT_EXCEEDED: 'general',
  TOO_LATE: 'hourglass',
  SUBMISSION_PERIOD_ENDED: 'hourglass'
};
export const ERROR_CONTENT = {
  GENERAL: {
    title: 'Oh no. Something happened.',
    lineOne: "Your feedback can't be delivered.",
    lineTwo: 'It looks like something went wrong.'
  },
  RATE_LIMIT_EXCEEDED: {
    title: 'Oh no! Your rate limit has been exceeded.',
    lineOne: "You've reloaded this survey link too many times.",
    lineTwo: 'Please wait a while before reloading this survey again.'
  },
  TOO_LATE: {
    title: "Oh no! It's a little late.",
    lineOne: "Your feedback can't be delivered.",
    lineTwo: 'Looks like the deadline has been and gone.'
  },
  SUBMISSION_PERIOD_ENDED: {
    title: 'This survey has expired',
    lineOne: 'This survey cannot be displayed.',
    lineTwo: 'Looks like the deadline has been and gone.'
  }
};