'use es6';

import { ERRORS, ERROR_CONTENT, ERROR_IMAGES, ERROR_TYPES } from './constants/ErrorConstants';
import buildPageFromProps from './utils/buildPageFromProps';
import isTestPage from './utils/isTestPage';
import { bestContrastColor } from './utils/bestContrastColor';
import { getSurveyLanguage } from '../surveyPreview/redux/selectors/survey';
import { RtlLangs } from '../surveyPreview/constants/RtlLangs';
const getErrorImageSource = errorImage => `https://static.hsappstatic.net/ui-images/static-2.343/optimized/errors/${errorImage}.svg`;
const injectErrorContent = errorType => {
  const errorContent = ERROR_CONTENT[errorType] || ERROR_CONTENT.GENERAL;
  document.querySelector('#error__image').src = getErrorImageSource(ERROR_IMAGES[errorType] || ERROR_IMAGES.GENERAL);
  document.querySelector('#error__title').textContent = errorContent.title;
  document.querySelector('#error__line-one').textContent = errorContent.lineOne;
  document.querySelector('#error__line-two').textContent = errorContent.lineTwo;
};
const findAndRemoveLoadingContainer = () => {
  const loadingContainer = document.querySelector('#loading__container');
  if (loadingContainer) {
    loadingContainer.parentNode.removeChild(loadingContainer);
  }
};
(function (formsReadyQueue) {
  const setupTestPage = () => {
    document.querySelector('.questionnaire-content').classList.add('is-test');
    window.HubSpotForms.currentForm.setContext({
      isTestPage: true
    });
  };
  const start = () => {
    window.HubSpotForms.currentForm.on('onFormDefinitionFetchSuccess', result => {
      buildPageFromProps(result);
      const {
        form: {
          themeColor
        } = {}
      } = result;
      const lang = getSurveyLanguage(result);
      const isLangRtl = RtlLangs.includes(lang.split('-')[0]);
      if (isLangRtl) {
        document.querySelector('.questionnaire-content').classList.add('is-rtl-survey');
      }
      const style = document.createElement('style');
      document.head.append(style);
      style.textContent = `
        .hs-form__actions__next,
        .hs-form__actions__previous,
        .hs-form__pagination-progress-inner,
        .hs-form__actions__submit
         {
          background-color: ${themeColor};
          border-color: ${themeColor};
          color: ${bestContrastColor(themeColor)};
        }

        .hs-form__actions__previous {
          border-color: ${themeColor};
          background-color: ${bestContrastColor(themeColor)};
          color: ${themeColor};
        }
      `;
    });
    window.HubSpotForms.currentForm.on('onFormDefinitionFetchError', (__, error) => {
      const {
        response
      } = error;
      document.querySelector('#error__container').classList.remove('hidden');
      if (response && response.data) {
        switch (response.data.error) {
          case ERRORS.RATE_LIMIT_EXCEEDED:
            return injectErrorContent(ERROR_TYPES.RATE_LIMIT_EXCEEDED);
          case ERRORS.SUBMISSION_PERIOD_ENDED:
            return injectErrorContent(ERROR_TYPES.SUBMISSION_PERIOD_ENDED);
          default:
            return injectErrorContent(ERROR_TYPES.GENERAL);
        }
      }
      return injectErrorContent(ERROR_TYPES.GENERAL);
    });
    window.HubSpotForms.currentForm.on('onRenderError', (key, error) => {
      if (key === 'FETCH_DEFINITION_ERROR') {
        const {
          response: {
            data: {
              message
            }
          }
        } = error;
        document.querySelector('.questionnaire-container').innerHTML = `<div class="forms-error">${message}</div>`;
        document.querySelector('.questionnaire-container').style.display = 'block';
      }
    });
    window.HubSpotForms.currentForm.on('onFormReady', () => {
      if (isTestPage()) {
        setupTestPage();
      }
      findAndRemoveLoadingContainer();
    });
    window.HubSpotForms.currentForm.on('onPageChange', () => {
      try {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } catch (e) {
        window.scrollTo(0, 0);
      }
    });
  };
  if (window.HubSpotForms) {
    start();
  }
  formsReadyQueue.push(start);
})(window.hsFormsOnReady);